import { Select } from "@chakra-ui/react";
import { toastError, toastErrorMsg } from "components/toasts/toastError";
import { useEffect, useState } from "react"
import { FiSearch } from "react-icons/fi";
import { useAppSelector } from "redux/hooks";
import api from "services/api";
import { ContractType } from "types/app";
import ContractsTable from "./components/ContractsTable";
import { useNavigate } from "react-router-dom";
import ButtonWidget from "../dashboard/components/ButtonWidget";
import { MdCancel, MdFreeCancellation, MdPending, MdStart } from "react-icons/md";
import dayjs from "dayjs";
import { IoContract } from "react-icons/io5";
import { generateXlsx } from "helpers/generateXlsx";

const ContractsList = () => {

    const user = useAppSelector(selector => selector.user.user);
    const darkmode = useAppSelector(selector => selector.layout.darkMode)
    const [search, setSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('-1');
    const [loadingPage, setLoadingPage] = useState(false)
    const [data, setData] = useState<ContractType[]>([])


	const [date1, setDate1] = useState(dayjs().startOf("month").toDate());
	const [date2, setDate2] = useState(dayjs().startOf("month").toDate());
	const [date3, setDate3] = useState(dayjs().startOf("month").toDate());
	const [date4, setDate4] = useState(dayjs().startOf("month").toDate());
	const [date5, setDate5] = useState(dayjs().startOf("month").toDate());

	const [orcamentosMes, setOrcamentosMes] = useState(0)
	const [orcamentosPerdidos, setOrcamentosPerdidos] = useState(0)
	const [orcamentosAndamento, setOrcamentosAndamento] = useState(0)
	const [orcamentosGanhos, setOrcamentosGanhos] = useState(0)
	const [ticketMedio, setTicketMedio] = useState(0)

	const [cardsData, setCardsData] = useState({
		iniciadosMes: 0,
		canceladosMes: 0,
		assinaturaPendente: 0,
		naoIniciados: 0,
		ativos: 0,
	})

    const navigate = useNavigate()

    const handleNovoContrato = () => {
        navigate('/admin/contracts/new')
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        loadData()
    }


	const handleExport = () => {
		api.get(`/contratos/report/download`, {
		  headers: {
			token: user?.token,
		  },
		}).then((res )=>{
		  const data = res.data
		  if(data){
			  const {name, sheetData, sheetName} = data
			  try{
				  generateXlsx(name, sheetName, sheetData)
			  }catch(err){
				  toastErrorMsg("Falha ao exportar relatório", false)
				  console.error(err)
			  }
		  }

		}).catch(err=>{
		  console.log(err)
		  toastError(err, darkmode)
	  })
	}


    const loadData = () => {
        api.get('/contracts/all', {
            headers: {
                token: user?.token
            },
            params: {
                search,
                status: statusSearch != '-1' ? statusSearch : undefined
            }
        }).then(res=>{
            setData(res.data)
        }).catch(err=> {
            toastError(err, darkmode)
        })

		api.get('/contracts/cards', {
			headers: {
				token: user?.token
			}
		}).then(res=> {
			if(res){
				if(res.data){
					setCardsData(res.data)
				}
			}
		}).catch(err=> {
			toastError(err, false)
		})
    }

    useEffect(()=> {
        loadData()
    }, [])

    return (
        <div className="mt-5">

			<div className="flex justify-between flex-col md:flex-row">
				<h1 className="text-2xl font-bold dark:text-white w-fit">Lista de Contratos</h1>

				<div className="flex md:items-center gap-5 flex-col md:flex-row">
					<form className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-5 items-center justify-center h-full w-full bg-white dark:!bg-navy-800 rounded-xl" onSubmit={handleSearch}>
						<div className="col-span-1 md:col-span-1 xl:col-span-3 2xl:col-span-5 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white">
							<p className="pl-3 pr-2 text-xl">
								<FiSearch className="h-12 w-4 text-gray-400 dark:text-white" />
							</p>
							<input
								value={search}
								onChange={e => setSearch(e.target.value)}
								type="text"
								placeholder="Procurar..."
								className="block h-full w-full rounded-full bg-white text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white "
							/>
						</div>

						<Select value={statusSearch} onChange={e => setStatusSearch(e.target.value)} icon={<></>} className="no-arrows text-sm col-span-1 flex h-12 w-full items-center rounded-full bg-white border border-gray-200 shadow-md shadow-600 text-navy-700 dark:bg-navy-900 dark:text-white px-5">
							<option value={-1}>Status</option>
							<option value={1}>Ativo</option>
							<option value={0}>Inativo</option>
						</Select>
						<button type="button" onClick={handleSearch} disabled={loadingPage} className="linear flex flex-none items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
							Procurar
						</button>
					</form>

					<button onClick={handleNovoContrato} className="linear flex flex-none items-center justify-center rounded-xl bg-blue-500 px-2 py-2 font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
						Novo contrato
					</button>

					{data.length > 0 && <button onClick={handleExport} className="linear flex-none ml-auto flex items-center justify-center rounded-xl bg-orange-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-darkgreen-400 dark:text-white dark:hover:bg-darkgreen-300 dark:active:bg-darkgreen-200">
						Baixar relatório
					</button>}
				</div>
			</div>

			<div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-5">
				<ButtonWidget
					searchDate={date1}
					setSearchDate={setDate1}
					icon={<MdStart className="h-7 w-7" />}
					title={"Iniciados no Mês"}
					subtitle={Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(cardsData.iniciadosMes)}
				/>
				<ButtonWidget
					searchDate={date4}
					setSearchDate={setDate4}
					icon={<MdCancel className="h-6 w-6" />}
					title={"Cancelados no Mês"}
					subtitle={Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(cardsData.canceladosMes)}
				/>
				<ButtonWidget
					searchDate={date3}
					setSearchDate={setDate3}
					icon={<MdPending className="h-7 w-7" />}
					title={"Assinatura Pendente"}
					subtitle={Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(cardsData.assinaturaPendente)}
				/>
				<ButtonWidget
					searchDate={date3}
					setSearchDate={setDate3}
					icon={<MdFreeCancellation className="h-7 w-7" />}
					title={"Assinados Mas Não Iniciados"}
					subtitle={Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(cardsData.naoIniciados)}
				/>
				<ButtonWidget
					searchDate={date3}
					setSearchDate={setDate3}
					icon={<IoContract className="h-7 w-7" />}
					title={"Contratos Ativos"}
					subtitle={Intl.NumberFormat("pt-BR", {
						style: "currency",
						currency: "BRL",
					}).format(cardsData.ativos)}
				/>
			</div>

            <div className="mt-5">
                <ContractsTable reloadTable={loadData} loading={loadingPage} tableData={data} />
            </div>
        </div>
    )
}

export default ContractsList