import React, { Dispatch, SetStateAction, useState } from "react";
import Card from "components/card";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Skeleton, Textarea, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { NFs } from "types/app";
import { BsEye, BsTrash } from "react-icons/bs";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ErrorData } from "types/fetch";
import api from "services/api";
import TooltipHorizon from "components/tooltip";
import { Text } from "@chakra-ui/react";
import { MdCancel, MdEdit } from "react-icons/md";
import { formatToCurrency } from "helpers/conversions";
import { cnpjMask } from "masks/cnpjMask";
import { toastError } from "components/toasts/toastError";
import { IFocusNF } from "types/nf";

interface NfModalCancel {
	opened: boolean,
	motivo_cancelamento?: string
    data?: {
		id?: number,
		data_emissao?: string,
		code?: string,
		value?: number
	}
}

interface NfModal {
	opened: boolean,
    data?: {
		id?: number,
		data_emissao?: string,
		ref?: string,
		url?: string,
		code?: string,
		client?: string,
		status?: IFocusNF["status"],
		description?: string,
		error?: string,
		value?: number,
	}
}

interface RowType extends NFs {
}

const columnHelper = createColumnHelper<RowType>();

// const columns = columnsDataCheck;
export default function NfsTable(props: {
  setEditData: Dispatch<SetStateAction<NFs>>,
  setOpenEditData: (val: boolean) => void,
  setLoadingEditData: Dispatch<SetStateAction<boolean>>
  reloadTable: ()=> void,
  tableData: RowType[],
  title?: string,
  loading: boolean,
  maxH?: number,
  minH?: number
}) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()

  const [selectedId, setSelectedId] = useState(null)

  const [extraClasses, setExtraClasses] = useState('')

  const [nfModalView, setNfModalView] = React.useState<NfModal>({
	opened: false,
	data: {}
  })

  const [atualizandoNota, setAtualizandoNota] = React.useState(false)

  const [nfModalCancel, setNfModalCancel] = React.useState<NfModalCancel>({
	opened: false,
	data: {}
  })
  const [cancelandoNF, setCancelandoNF] = React.useState(false)

  const handleOpenInfo = (data: CellContext<RowType, any>) => {
    const id = data.row.original.id
	props.setLoadingEditData(true)


	api.get('/nfs/show/'+id, {
		headers: {
		  token: user?.token
		}
	  }).then((res: {data: NFs})=>{
		  if(res){
			  if(res.data){
				  setNfModalView({
					  opened: true,
					  data: {
						  id: res.data.id,
						  data_emissao: res.data.date,
						  code: res.data.number,
						  value: res.data.value,
						  client: res.data.empresaNome,
						  url: res.data.url,
						  description: res.data.description,
						  error: res.data.erro,
						  status: res.data.status as any,
						  ref: res.data.ref
					  }
				  })
			  }
		  }
	  }).catch((error: ErrorData)=>{
		toast(error.response.data.error, {
			type: "error",
			theme: "light"
		})
	  })


  }

  const handleCancel = (data: CellContext<RowType, any>) => {
    const id = data.row.original.id
    Swal.fire({
		target: ".chakra-modal__content",
		title: "Cancelar nota fiscal " + data.row.original.number,
		text: "Você realmente deseja cancelar a " + data.row.original.description + " do cliente " + data.row.original.empresaNome + " ?",
		icon: "info",
		confirmButtonText: "Sim",
		showCancelButton: true,
		cancelButtonText: "Não",
		background: "#fff",
		color: "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.get('/nfs/show/'+id, {
          headers: {
            token: user?.token
          }
        }).then((res: {data: NFs})=>{
			if(res){
				if(res.data){
					setNfModalCancel({
                        opened: true,
                        data: {
							id: res.data.id,
                            data_emissao: res.data.date,
                            code: res.data.number,
							value: res.data.value
						}
                    })
				}
			}
        }).catch((error: ErrorData)=>{
          toast(error.response.data.error, {
              type: "error",
              theme: "light"
          })
        })
      }
    })
  }

  const handleCancelNF = () => {
	if(cancelandoNF) return;
	setCancelandoNF(true)
	api.post("/nfs/cancelar/"+nfModalCancel.data.id, {
		motivo_cancelamento: nfModalCancel.motivo_cancelamento
	}, {
		headers: {
            token: user?.token,
        }
	}).then(()=> {
		toast("Nota fiscal cancelada com sucesso!", {
            type: "success",
            theme: "light"
        })
        onCloseCancelNF()
        setCancelandoNF(false)
        props.reloadTable()
	}).catch(err=> {
        setCancelandoNF(false)
		toastError(err, false)
	})
  }

  const onCloseCancelNF = () => {
	setNfModalCancel({
		opened: false,
		motivo_cancelamento: "",
		data: {

		}
	})
  }

  const onCloseViewNF = () => {
	setNfModalView({
        opened: false,
        data: {}
    })
  }

  const handleAttNota = () => {
	if(atualizandoNota) return;
	setAtualizandoNota(true)

	api.get("/nfs/atualizar/"+nfModalView.data.id, {
		headers: {
            token: user?.token,
        }
	}).then((res: {data: {nf?: NFs, ok: boolean}})=> {
		toast("Dados atualizados com sucesso!", {
            type: "success",
            theme: "light"
        })

		setNfModalView({
			opened: true,
			data: {
				id: res.data.nf.id,
				data_emissao: res.data.nf.date,
				code: res.data.nf.number,
				value: res.data.nf.value,
				client: res.data.nf.empresaNome,
				url: res.data.nf.url,
				description: res.data.nf.description,
				error: res.data.nf.erro,
				status: res.data.nf.status as any,
				ref: res.data.nf.ref
			}
		})

		setTimeout(()=> {
			setAtualizandoNota(false)
		}, 2000)

        props.reloadTable()
	}).catch(err=> {
		setTimeout(()=> {
			setAtualizandoNota(false)
		}, 2000)
		toastError(err, false)
	})

  }

  const columns = [
    columnHelper.accessor("date", {
      id: "date",
	  size: 90,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATA</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {info.getValue() ? dayjs(info.getValue()).isValid() ? dayjs(info.getValue()).format("DD/MM/YYYY") : "Data inválida" : "Não definido"}
        </p>
      ),
    }),
    columnHelper.accessor("number", {
      id: "number",
	  size: 120,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">REF</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {info.getValue() || "Não definido"}
        </p>
      ),
    }),
    columnHelper.accessor("value", {
      id: "value",
	  size: 120,
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">VALOR</p>
      ),
      cell: (info) => (
        <p className="flex items-center text-xs text-navy-700 dark:text-white">
          {info.getValue() !== undefined ? formatToCurrency(info.getValue()) : "Não definido"}
        </p>
      ),
    }),
    columnHelper.accessor(null, {
      id: "acoes",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AÇÕES
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 items-center">
			<TooltipHorizon
				content={<span>Informações</span>}
				trigger={
					<button onClick={()=> handleOpenInfo(info)} className="linear rounded-[20px] px-1 py-1 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<BsEye />
					</button>
				}
				extra=""
				placement="top"
			/>
			{(info.row.original.status !== "cancelado" && info.row.original.status !== "erro_autorizacao") &&
				<TooltipHorizon
					content={<span>Cancelar Nota Fiscal</span>}
					trigger={
						<button onClick={()=> handleCancel(info)} className="linear rounded-[20px] px-1 py-1 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
							<MdCancel />
						</button>
					}
					extra=""
					placement="top"
				/>
			}
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
		<ChakraProvider>
			<Modal isOpen={nfModalCancel.opened} onClose={onCloseCancelNF} isCentered>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">Cancelar NF</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
						<div className="grid grid-cols-1 gap-2">
							<FormControl className="flex flex-col gap-1 w-full">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Número</FormLabel>
								<Input type="text" value={nfModalCancel.data.code} disabled className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
							</FormControl>
							<FormControl className="flex flex-col gap-1 w-full">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data</FormLabel>
								<Input type="text" value={nfModalCancel.data?.data_emissao ? dayjs(nfModalCancel.data.data_emissao).isValid() ? dayjs(nfModalCancel.data.data_emissao).format("DD/MM/YYYY") : "Data inválida": "Data não definida"} disabled className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
							</FormControl>
							<FormControl className="flex flex-col gap-1 w-full">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Valor</FormLabel>
								<Input type="text" value={nfModalCancel.data?.value ? formatToCurrency(nfModalCancel.data?.value) : "Valor inválido"} disabled className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
							</FormControl>
							<FormControl className="flex flex-col gap-1 w-full col-span-1">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Motivo de cancelamento</FormLabel>
								<Textarea defaultValue={nfModalCancel.motivo_cancelamento} onBlur={(e)=> {
									const _nfmodal = nfModalCancel
									_nfmodal.motivo_cancelamento = e.target.value

									setNfModalCancel({..._nfmodal})
								}} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
							</FormControl>
						</div>
					</ModalBody>
				<ModalFooter>
					<Button disabled={cancelandoNF} colorScheme='red' mr={3} onClick={onCloseCancelNF}>
						Fechar
					</Button>
					<Button disabled={cancelandoNF} onClick={()=> {
						handleCancelNF()
					}} colorScheme={cancelandoNF ? "red" : "green"} variant='solid'>Cancelar NF</Button>
				</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={nfModalView.opened} onClose={onCloseViewNF} isCentered>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">Nota fiscal {nfModalView.data.code}{nfModalView.data.status === "cancelado" ? " - CANCELADA" : ""}</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
						<div className="grid grid-cols-1 gap-2">
							<div className="grid grid-cols-2 gap-2">
								<FormControl className="flex flex-col gap-1 w-full">
									<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data</FormLabel>
									{atualizandoNota ?
										<Skeleton className="w-full h-[40px]"></Skeleton>
									:
										<Input type="text" value={nfModalView.data?.data_emissao ? dayjs(nfModalView.data.data_emissao).isValid() ? dayjs(nfModalView.data.data_emissao).format("DD/MM/YYYY") : "Data inválida": "Data não definida"} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
									}
								</FormControl>
								<FormControl className="flex flex-col gap-1 w-full">
									<FormLabel className="font-bold text-sm pl-2 dark:text-white">Valor</FormLabel>
									{atualizandoNota ?
										<Skeleton className="w-full h-[40px]"></Skeleton>
									:
										<Input type="text" value={nfModalView.data?.value ? formatToCurrency(nfModalView.data?.value) : "Valor inválido"} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Digite aqui o motivo do cancelamento da NF" />
									}
								</FormControl>
							</div>
							<FormControl className="flex flex-col gap-1 w-full">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Status</FormLabel>
								{atualizandoNota ?
									<Skeleton className="w-full h-[40px]"></Skeleton>
								:
									<Input type="text" value={nfModalView.data?.status == "autorizado" ? "Nota autorizada" : nfModalView.data?.status == "cancelado" ? "Nota cancelada" : nfModalView.data?.status == "erro_autorizacao" ? "Erro ao gerar nota" : "Processando nota"} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2"  />
								}
							</FormControl>
							<FormControl className="flex flex-col gap-1 w-full">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Cliente</FormLabel>

								{atualizandoNota ?
									<Skeleton className="w-full h-[40px]"></Skeleton>
								:
									<Input type="text" value={nfModalView.data?.client || "Cliente inválido"} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2"  />
								}
							</FormControl>
							<FormControl className="flex flex-col gap-1 w-full col-span-1">
								<FormLabel className="font-bold text-sm pl-2 dark:text-white">Descrição</FormLabel>

								{atualizandoNota ?
									<Skeleton className="w-full h-[60px]"></Skeleton>
								:
									<Textarea value={nfModalView.data.description} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
								}
							</FormControl>
							{nfModalView.data.status === "erro_autorizacao" &&
								<FormControl className="flex flex-col gap-1 w-full col-span-1">
									<FormLabel className="font-bold text-sm pl-2 dark:text-white">Erro</FormLabel>
									{atualizandoNota ?
										<Skeleton className="w-full h-[60px]"></Skeleton>
									:
										<Textarea value={nfModalView.data.error} className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" />
									}
								</FormControl>
							}
						</div>
					</ModalBody>
				<ModalFooter>
					<Button disabled={cancelandoNF} colorScheme='red' mr={3} onClick={onCloseViewNF}>
						Fechar
					</Button>
					<Button className="disabled:cursor-not-allowed" colorScheme='yellow' mr={3} onClick={handleAttNota}>
						Atualizar nota
					</Button>
					{(nfModalView.data.status === "autorizado" || nfModalView.data.status === "cancelado") &&
						<Button disabled={cancelandoNF} onClick={()=> {
							window.open(nfModalView.data.url)
						}} colorScheme="green" variant='solid'>Ir para NF</Button>
					}
				</ModalFooter>
				</ModalContent>
			</Modal>
		</ChakraProvider>
	  	<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto border border-gray-200"} noShadow>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-navy-700 dark:text-white">
				{props.title}
			</div>
		</div>

		<div className="overflow-x-scroll">
			<table className="w-full">
			<thead className="table w-full">
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
                        style={{width: header.getSize(), maxWidth: header.getSize(), minWidth: header.getSize()}}
						className="border-b-[1px] border-gray-200 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-200">
							{flexRender(
                header.column.columnDef.header,
                header.getContext()
							)}
							{{
							asc: "",
							desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody className={`overflow-y-auto block w-full`} style={{
        height: props.minH ? props.minH : undefined,
        minHeight: props.minH ? props.minH : undefined,
        maxHeight: props.maxH ? props.maxH : undefined,
      }}>
				{table
				.getRowModel()
				.rows
				.map((row) => {
					return (
					<tr key={row.id} className="table w-full hover:bg-gray-50">
						{row.getVisibleCells().map((cell) => {
						return (
              <td
                key={cell.id}
                style={{width: cell.column.getSize() == 100 ? '100%': cell.column.getSize(), maxWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize(), minWidth: cell.column.getSize() == 100 ? "100%": cell.column.getSize()}}
                className={` border-white/0 py-1 pr-2 `}
              >
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </td>
						);
						})}
					</tr>
					);
				})}
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum gasto encontrado...</h1> }
			</tbody>
			</table>
		</div>

		</Card>
	</>
  );
}
