import React from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import Progress from "components/progress";
import { MdArrowCircleLeft, MdArrowLeft, MdArrowRight, MdCancel, MdCheckCircle, MdChevronLeft, MdChevronRight, MdDelete, MdEdit, MdOutlineError, MdPictureAsPdf, MdTimer } from "react-icons/md";

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Tooltip } from "@chakra-ui/tooltip";
import api from "services/api";
import { useAppSelector } from "redux/hooks";
import { toast } from "react-toastify";
import { toastError } from "components/toasts/toastError";
import { Button, ChakraProvider, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { ContractPaymentType, ContractType, NFs } from "types/app";
import { getMonth } from "helpers/months";
import Swal from "sweetalert2";
import { ErrorData } from "types/fetch";
import { IoMdCash, IoMdDownload, IoMdPaper } from "react-icons/io";
import { BsCheck } from "react-icons/bs";
import Dropdown from "components/dropdown";
import Calendar from "react-calendar";
import ReactPDF, { pdf, BlobProvider, PDFViewer } from "@react-pdf/renderer";
import ContractDocument from "./ContractDocument";
import TooltipHorizon from "components/tooltip";
import { tiposContratos } from "variables/contratos";
import NFsCard from "./NFsCard";

interface RowType extends ContractType{

}

interface NfModal {
	open: boolean,
    data?: {
		id?: number,
		data_emissao?: string,
	}
}

const columnHelper = createColumnHelper<RowType>();

// const columns = columnsDataCheck;
export default function ContractsTable(props: { reloadTable: ()=> void, tableData: RowType[], title?: string, loading: boolean }) {
  const { tableData } = props;
  const user = useAppSelector(state=> state.user.user)
  const darkmode = useAppSelector(state=> state.layout.darkMode)
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [defaultData, setDefaultData] = React.useState([])
  const navigate = useNavigate()
  const [signature, setSignature] = React.useState(-1)
  const [opened, setOpened] = React.useState(null)

  const { isOpen: isOpenPayments, onOpen: onOpenPayments, onClose: onClosePayments } = useDisclosure()
  const { isOpen: isOpenNewPayment, onOpen: onOpenNewPayment, onClose: onCloseNewPayment } = useDisclosure()
  const { isOpen: isOpenPaymentDate, onOpen: onOpenPaymentDate, onClose: onClosePaymentDate } = useDisclosure()

  const { isOpen: isOpenContract, onOpen: onOpenContract, onClose: onCloseContract } = useDisclosure()

  const [nfModal, setNfModal] = React.useState<NfModal>({
	open: false,
	data: {}
  })

  const [nfsData, setNfsData] = React.useState<NFs[]>([])

  const [generatingNF, setGeneratingNF] = React.useState(false)
  const [openedContractData, setOpenedContractData] = React.useState<RowType>()
  const [newPaymentData, setNewPaymentData] = React.useState<{date: Date}>({
    date: dayjs().toDate()
  })

  const [test, setTest] = React.useState("")
  const [loadingContracts, setLoadingContracts] = React.useState(true)

  const handleDelete = (data: CellContext<RowType, any>) => {
    Swal.fire({
      title: "Remover Contrato",
      text: "Você realmente deseja remover o contrato de "+(data.row.original.client ? data.row.original.client?.name : "Não Definido")+"?",
      icon: "question",
      confirmButtonText: "Sim",
      showCancelButton: true,
      cancelButtonText: "Não",
      background: darkmode ? "rgb(11 20 55)" : "#fff",
      color: darkmode ? "#fff" : "#222",
    }).then(response=> {
      if(response.isConfirmed){
        api.delete('/contracts/delete/'+data.row.original.id, {
          headers: {
            token: user?.token
          }
        }).then(()=>{
          props.reloadTable()
          toast("Contrato removido com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
          })
        }).catch((error: ErrorData)=>{
          // console.log(error)
          toast(error.response.data.error, {
              type: "error",
              theme: darkmode ? "dark" : "light"
          })
      })
      }
    })
  }

  const loadContractNfs = () => {
	setLoadingContracts(true)

	api.get("/nfs/contrato/"+(nfModal.data?.id || 0), {
		headers: {
			token: user?.token,
		}
	}).then(res=> {
		setNfsData([...res.data])
		setLoadingContracts(false)
	}).catch(err=> {
		toastError(err, false)
		setLoadingContracts(false)
	})
  }
  const fisrLoadContractNfs = (id: number) => {
	setLoadingContracts(true)

	api.get("/nfs/contrato/"+(id || 0), {
		headers: {
			token: user?.token,
		}
	}).then(res=> {
		setNfsData([...res.data])
		setLoadingContracts(false)
	}).catch(err=> {
		toastError(err, false)
		setLoadingContracts(false)
	})
  }

  const handleEdit = (data: CellContext<RowType, any>) => {
    navigate('/admin/contracts/edit/'+data.row.original.id)
  }

  const handleDownload = (data: CellContext<RowType, any>) => {
	onOpenContract()
  }

  const handleFaturamento = (data: CellContext<RowType, any>) => {
    setOpenedContractData(data.row.original)
    onOpenPayments()
  }

  const handleCloseFaturamento = () => {
    setOpenedContractData(null)
    onClosePayments()
  }

  const handleOpenNewPayment = () => {
    setNewPaymentData({
      date: dayjs().toDate()
    })
    onOpenNewPayment()
  }

  const handleCreatePayment = () => {
    Swal.fire({
        customClass: {
          container: "z-[9000]"
        },
        title: "Criar novo pagamento?",
        text: "Você realmente deseja criar um novo pagamento para o contrato de " + openedContractData?.client.name + " no valor de " + Intl.NumberFormat('pt-BR', {style: "currency", currency: "BRL"}).format(openedContractData?.value) + "?",
        icon: "question",
        confirmButtonText: "Sim",
        showCancelButton: true,
        cancelButtonText: "Não",
        background: darkmode ? "rgb(11 20 55)" : "#fff",
        color: darkmode ? "#fff" : "#222",
      }).then(response=> {
        if(response.isConfirmed){
          api.post("/contract/payment/create/"+openedContractData?.id, {
            date: dayjs(newPaymentData.date).toISOString()
          }, {
            headers: {
              token: user?.token
            }
          }).then((res)=> {
            toast("Pagamento criado com sucesso!", {
              type: "success",
              theme: darkmode ? "dark" : "light"
            })

            props.reloadTable()

            const _newPayments = openedContractData.payments

            _newPayments.push(res.data)

            setOpenedContractData({
              ...openedContractData,
              payments: _newPayments
            })

            setNewPaymentData({
              date: dayjs().toDate()
            })

            onCloseNewPayment()

          }).catch(err=> {
            toastError(err, darkmode)
          })
        }
    })
  }

  const handleCancelCreatePayment = () => {

    setNewPaymentData({
      date: dayjs().toDate()
    })
    onCloseNewPayment()
  }

  const handleTooglePayment = (paymentId: number) => {
    api.put('/contracts/payment/toogle/'+paymentId, {}, {
      headers: {
        token: user?.token
      }
    }).then(()=> {
      props.reloadTable()

      const _newPayments = openedContractData.payments
      if(_newPayments[_newPayments.findIndex(payment=> payment.id == paymentId)]){
        _newPayments[_newPayments.findIndex(payment=> payment.id == paymentId)].paid = !_newPayments[_newPayments.findIndex(payment=> payment.id == paymentId)].paid
      }
      setOpenedContractData({
        ...openedContractData,
        payments: _newPayments
      })

      toast("Pagamento atualizado com sucesso", {
        type: "success",
        theme: darkmode ? "dark" : "light",
        autoClose: 500
      })
    }).catch(err=> {
      toastError(err, darkmode)
    })
  }

  const handleNF = (data: CellContext<RowType, any>) => {
	const _modal = nfModal
	_modal.open = true
	_modal.data.id = data.row.original.id
	fisrLoadContractNfs(data.row.original.id)
	setNfModal({..._modal})
  }

  const onCloseNF = () => {
	const _modal = {
		open: false,
		data: {}
	}
	setNfModal({..._modal})
  }

  const handleGenerateNF = () => {
	if(generatingNF) return;
	setGeneratingNF(true)
	api.post("/contract/nf/"+nfModal.data.id, {
		data: nfModal.data.data_emissao
	}, {
		headers: {
            token: user?.token
        }
	}).then(()=> {
        toast("Nota Fiscal colocada na fila de emissão, acompanhe o processo na aba de NFs!", {
            type: "success",
            theme: "light"
        })
		loadContractNfs()
		setGeneratingNF(false)
	}).catch(err=> {
		setGeneratingNF(false)
		toastError(err, false)
	})
  }

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">ID</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("owner.name", {
      id: "ownerName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CRIADO POR</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("client.name", {
      id: "clientName",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">CLIENTE</p>
      ),
      cell: (info) => (
        <p className="text-xs text-navy-700 dark:text-white">
          {info.getValue() ? info.getValue() : "Não definido"}
        </p>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          DATA DE CRIAÇÃO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {dayjs(info.getValue()).format("DD/MM/YYYY")}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("tipoContratoId", {
      id: "tipoContratoId",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          TIPO DE CONTRATO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {tiposContratos.find(t=> t.id == info.getValue())?.nome || "Não definido"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("active", {
      id: "active",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          STATUS
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className={`text-xs ${info.getValue() == false ? "bg-red-500" : info.getValue() == true ? "bg-green-600" : ""} p-1 rounded text-white`}>
            {info.getValue() == true ? "Ativo" : info.getValue() == false ? "Inativo" : ""}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("value", {
      id: "value",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          VALOR
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {Intl.NumberFormat("pt-BR", {
              currency: "BRL",
              style: "currency",

            }).format(info.getValue())}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("birthday", {
      id: "birthday",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ANIVERSÁRIO
        </p>
      ),
      cell: (info) => (
        <div className="flex items-center">
          <p className="text-xs text-navy-700 dark:text-white">
            {(info.getValue() != undefined && info.getValue() != null) ? getMonth(info.getValue()) : "Não definido"}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor(null, {
      id: "acoes",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          AÇÕES
        </p>
      ),
      cell: (info) => (
        <div className="flex gap-2 items-center">
			<TooltipHorizon
				content={<span>Gerar NF</span>}
				trigger={
					<button onClick={()=> handleNF(info)} className="linear relative rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
					  <IoMdPaper />
					</button>
				}
				extra=""
				placement="top"
			/>
			<TooltipHorizon
				content={<span>Faturamento</span>}
				trigger={
					<button onClick={()=> handleFaturamento(info)} className="linear relative rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						{info.row.original.paymentsOrdered?.toPay.length > 0 && <div className="rounded-full w-[10px] h-[10px] bg-red-400 absolute top-[-2px] right-[-2px]"></div>}
						<IoMdCash />
					</button>
				}
				extra=""
				placement="top"
			/>
			<TooltipHorizon
				content={<span>Editar</span>}
				trigger={
					<button onClick={()=> handleEdit(info)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdEdit />
					</button>
				}
				extra=""
				placement="top"
			/>
			<TooltipHorizon
				content={<span>Remover</span>}
				trigger={
					<button onClick={() => handleDelete(info)} className="linear rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-orange-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
						<MdDelete />
					</button>
				}
				extra=""
				placement="top"
			/>
        </div>
      ),
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(()=>{
    setData(()=> [...tableData])
  }, [tableData])
  return (
	<>
		<ChakraProvider>


			<Modal isOpen={isOpenNewPayment} onClose={handleCancelCreatePayment}>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">Registrar nova cobrança</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
            <FormControl className="w-full">
                <FormLabel className="text-gray-600 dark:text-white">Data da cobrança</FormLabel>
                <Dropdown
                    button={
                    <button
                        type="button"
                        onClick={() => onOpenPaymentDate()}
                        className={`no-arrows text-gray-800 dark:text-white bg-lightPrimary dark:bg-navy-900 rounded w-full p-2`}
                    >
                        {newPaymentData.date ? dayjs(newPaymentData.date).format("DD/MM/YYYY") : "Clique para definir"}
                    </button>
                    }
                    extraClassesWrapper="w-full h-10"
                    extraClassesButton="w-full h-10"
                    animation={"origin-top-right transition-all duration-300 ease-in-out"}
                    classNames={`top-11 right-0 w-max `}
                    children={
                    <div className="z-50 w-max rounded-xl bg-white dark:text-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <Calendar
                            value={newPaymentData.date}
                            onClickDay={e=> setNewPaymentData({date: e})}
                            prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
                            nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
                            view={"month"}
                        />
                    </div>
                    }
                />
            </FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={handleCancelCreatePayment}>
							Cancelar
						</Button>
						<Button colorScheme='green' mr={3} onClick={handleCreatePayment}>
							Criar
						</Button>
						{/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={isOpenPayments} onClose={handleCloseFaturamento}>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">Pagamentos pendentes <p className="text-xs">{openedContractData?.client.name}</p></ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
					<ul className="flex flex-col gap-2">
					{openedContractData?.payments.map((payment)=>(
						<li className="grid grid-cols-3 text-xs gap-2" key={"PAYMENT-"+payment.contractId + "-" + payment.id}>
						<span className="relative self-center">
							{dayjs(payment.date).format("DD/MM/YYYY")}
						</span>
						<span className="justify-self-end self-center">{Intl.NumberFormat("pt-BR", {style: "currency", currency: "BRL"}).format(payment?.value)}</span>
						<div className="flex items-center gap-2">
							<Tooltip label="Alternar entre Pago/Não Pago" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
							<button onClick={()=> handleTooglePayment(payment.id)} className="linear relative rounded-[20px] bg-lightPrimary px-2 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20">
								<BsCheck />
							</button>
							</Tooltip>

							{!payment.paid && <Tooltip label="A pagar" className="rounded" bg={"#000000e0"} color={"white"} rounded={"base"} p={5} placement="top">
							<div className="rounded-full w-[10px] h-[10px] bg-red-400"></div>
							</Tooltip>}
						</div>
						</li>
					))}
					</ul>
				</ModalBody>
			<ModalFooter>
            	<Button colorScheme="green" mr={2} onClick={handleOpenNewPayment}>Criar novo pagamento</Button>
				<Button colorScheme='red' mr={3} onClick={handleCloseFaturamento}>
					Fechar
				</Button>
				{/* <Button onClick={handleGeneratePDF} colorScheme="green" variant='solid'>Gerar</Button> */}
			</ModalFooter>
			</ModalContent>

			</Modal>

			<Modal isOpen={isOpenContract && false} onClose={onCloseContract}>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">GERAR CONTRATO</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
						<input value={test} onChange={e=> setTest(e.target.value)} />
					</ModalBody>
				<ModalFooter>
					<Button colorScheme='red' mr={3} onClick={onCloseContract}>
						Fechar
					</Button>
					<Button onClick={()=> {
						pdf(<ContractDocument name={test} />).toBlob().then(blob=> {
							var fileURL = window.URL.createObjectURL(blob);
							let tab = window.open();
							tab.location.href = fileURL;
						})
					}} colorScheme="green" variant='solid'>Gerar</Button>
				</ModalFooter>
				</ModalContent>
			</Modal>


			<Modal isOpen={false} onClose={onCloseNF} isCentered>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white">GERAR NF</ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col">
						<FormControl className="flex flex-col gap-1 w-full">
							<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de geração</FormLabel>
							<Input defaultValue={nfModal.data.data_emissao ? dayjs(nfModal.data.data_emissao).format('YYYY-MM-DDTHH:mm:ss') : null} onBlur={(e)=> {
								const _nfmodal = nfModal
								_nfmodal.data.data_emissao = dayjs(e.target.value).toISOString()

								setNfModal({..._nfmodal})
							}} type="datetime-local" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Grupo" />
						</FormControl>
					</ModalBody>
				<ModalFooter>
					<Button disabled={generatingNF} colorScheme='red' mr={3} onClick={onCloseNF}>
						Fechar
					</Button>
					<Button disabled={generatingNF} onClick={()=> {
						handleGenerateNF()
					}} colorScheme="green" variant='solid'>Gerar</Button>
				</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal isOpen={nfModal.open} id="nfModal" onClose={onCloseNF} size={"6xl"} isCentered>
				<ModalOverlay />
				<ModalContent className="dark:bg-navy-800">
					<ModalHeader className="dark:text-white"></ModalHeader>
					<ModalCloseButton className="dark:text-white" />
					<ModalBody className="dark:text-white flex flex-col max-h-[280px] min-h-[280px]">
						<div className="grid grid-cols-2 gap-5">
							<div className="">
								<NFsCard data={nfsData} loadData={loadContractNfs} loading={loadingContracts} />
							</div>


							<div className="flex flex-col">
								<div className="flex justify-between mb-5">
									<h2 className="text-lg font-bold text-navy-700 dark:text-white">
										Emitir nova NF
									</h2>
								</div>


								<FormControl className="flex flex-col gap-1 w-full">
									<FormLabel className="font-bold text-sm pl-2 dark:text-white">Data de geração</FormLabel>
									<Input defaultValue={nfModal.data.data_emissao ? dayjs(nfModal.data.data_emissao).format('YYYY-MM-DDTHH:mm:ss') : null} onBlur={(e)=> {
										const _nfmodal = nfModal
										_nfmodal.data.data_emissao = dayjs(e.target.value).toISOString()

										setNfModal({..._nfmodal})
									}} type="datetime-local" className="no-arrows text-gray-800 dark:text-white bg-white border border-gray-200 shadow-md shadow-600 dark:bg-navy-900 rounded w-full p-2" placeholder="Grupo" />
								</FormControl>

								<Button className="ml-auto mt-2" disabled={generatingNF} onClick={()=> {
									handleGenerateNF()
								}} colorScheme="green" variant='solid'>Gerar</Button>
							</div>
						</div>
					</ModalBody>
				<ModalFooter>
				</ModalFooter>
				</ModalContent>
			</Modal>
		</ChakraProvider>
		<Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
		<div className="relative flex items-center justify-between pt-4">
			<div className="text-xl font-bold text-gray-900 dark:text-white">
			{props.title}
			</div>
		</div>

		<div className="mt-8 overflow-x-scroll">
			<table className="w-full">
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id} className="!border-px !border-gray-400">
					{headerGroup.headers.map((header) => {
					return (
						<th
						key={header.id}
						colSpan={header.colSpan}
						className="border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
						>
						<div className="items-center justify-between text-xs text-gray-200">
							{flexRender(
                header.column.columnDef.header,
                header.getContext()
							)}
							{{
							asc: "",
							desc: "",
							}[header.column.getIsSorted() as string] ?? null}
						</div>
						</th>
					);
					})}
				</tr>
				))}
			</thead>
			<tbody>
				{table
				.getRowModel()
				.rows
				.map((row) => {
					return (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => {
						return (
              <td
                key={cell.id}
                className={`min-w-[150px] border-white/0 py-3 pr-2 `}
              >
                {flexRender(
                  cell.column.columnDef.cell,
                  cell.getContext()
                )}
              </td>
						);
						})}
					</tr>
					);
				})}
			</tbody>
			</table>
			{props.loading ? <h1 className="text-bold dark:text-white text-center my-10">Carregando...</h1> : tableData.length <= 0 && <h1 className="text-bold dark:text-white text-center my-10">Nenhum orçamento encontrado...</h1> }
		</div>

		</Card>
	</>
  );
}
