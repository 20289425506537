import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import ProfileDev from "views/admin/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdAttachMoney,
  MdWeb,
  MdAdd,
  MdPeople,
  MdPages,
  MdDiscount,
  MdCalculate,
  MdAdminPanelSettings,
  MdDesignServices,
  MdDashboard,
} from "react-icons/md";

import { PiMicrosoftExcelLogo, PiWrench } from 'react-icons/pi'
import { HiChartPie, HiDocumentReport } from 'react-icons/hi'
import Clients from "views/clients";
import EditClient from "views/clients/subpages/EditClient";
import Orcamentos from "views/orcamentos";
import NewClient from "views/clients/subpages/NewClient";
import Users from "views/admin/users";
import EditUser from "views/admin/users/subpages/EditUser";
import NewUser from "views/admin/users/subpages/NewUser";
import NewRole from "views/admin/users/subpages/NewRole";
import Profile from "views/profile";
import { BsCash, BsFileExcel, BsFilePdf } from "react-icons/bs";
import PDAdminPage from "views/pdf";
import EditPDFPage from "views/pdf/subpages/EditPage";
import Categorys from "views/categorys";
import EditRole from "views/admin/users/subpages/EditRole";
import NewOrder from "views/orcamentos/subpages/NewOrder";
import EditOrder from "views/orcamentos/subpages/EditOrder";
import PendingDiscounts from "views/orcamentos/subpages/PendingDiscounts";
import DashboardContratos from "views/dashboardcontratos";
import RelatorioOrcamentos from "views/relatorioorcamentos";
import RelatorioMetas from "views/relatoriometas";
import QuickLinks from "views/quicklinks";
import { AiOutlineAim } from "react-icons/ai";
import ScopesOfService from "views/scopesofservice";
import NewScope from "views/scopesofservice/subpages/NewScope";
import EditScope from "views/scopesofservice/subpages/EditScope";
import { FaClipboardList, FaFileContract } from "react-icons/fa";
import ContractsDashboard from "views/contracts/dashboard";
import ContractsList from "views/contracts/list";
import ContractsNew from "views/contracts/new";
import { IoIosPeople } from "react-icons/io";
import Contacts from "views/contacts";
import NewContact from "views/contacts/subpages/NewContact";
import EditContact from "views/contacts/subpages/EditContact";
import { TiTicket } from "react-icons/ti";
import GLPITickets from "views/glpitickets";
import ContractsEdit from "views/contracts/edit";
import FuncionariosList from "views/funcionarios/list";
import FuncionariosEdit from "views/funcionarios/edit";
import ExpensesDashboard from "views/funcionarios/dashboard/ExpensesDashboard";
import { TbUserDollar } from "react-icons/tb";
import RelatorioSalario from "views/funcionarios/relatoriosalarios";
import SheetsAdmin from "views/sheets/admin";
import SheetsUserSheet from "views/sheets/sheet/subpages/userSheet";
import { SiGooglesheets } from "react-icons/si";
import { RiDashboardFill } from "react-icons/ri";
import { IoSettings } from "react-icons/io5";
import UserSheets from "views/sheets/sheet";
import SheetsDashboard from "views/sheets/dashboard";
import RDVSheet from "views/sheets/rdv";
import SalesSheet from "views/sheets/planilhaVendas";
import { FiPercent } from "react-icons/fi";
import TaxasConfig from "views/taxas";
import ComissoesGlobais from "views/comissoesglobais";
import DashboardVendas from "views/dashboardvendas";
import DespesasOperacao from "views/despesasoperacao";
import Operacoes from "views/operacoes";
import NewOperacao from "views/operacoes/subpages/NewOperacao";
import EditOperacao from "views/operacoes/subpages/EditOperacao";
import PosVendaSheet from "views/sheets/planilhaPosVenda";
import ComissoesDistribuidor from "views/comissoesdistribuidor";
import EmpresasCadastradas from "views/empresascadastradas";
import NewGrupo from "views/admin/users/subpages/NewGrupo";
import EditGrupo from "views/admin/users/subpages/EditGrupo";
import DashboardFinanceiro from "views/dashboardfinanceiro";
import ConfiguracoesFinanceiro from "views/financeiro/configuracoes";
import NFsList from "views/nfs";

export const devMode = false

const routes: {
    name: string;
    layout?: "/admin" | "/auth" | "dropdown" | "separator" | "external" | "/rtl";
    path?: string;
    forDevs?: boolean;
    icon?: JSX.Element;
    component?: JSX.Element;
    hidden ?: boolean;
    admin?: boolean;
    permission?: string;
    dropdownLinks?: {
      name: string;
      layout?: string;
      path?: string;
      forDevs?: boolean;
      icon?: JSX.Element;
      component?: JSX.Element;
      hidden ?: boolean;
	  permission ?: string;
      admin?: boolean;
    }[]
}[] = [
  {
    name: "Desenvolvedor",
    layout: "separator",
    forDevs: true,
    path: null,
    icon: null,
    component: null,
  },
  {
    name: "Dashboards",
    layout: "/admin",
    path: "dev-dashboard",
    forDevs: true,
    icon: <MdBarChart className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Marketplace",
    layout: "/admin",
    path: "dev-marketplace",
    forDevs: true,
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "dev-profile",
    forDevs: true,
    icon: <MdPerson className="h-6 w-6" />,
    component: <ProfileDev />,
  },





  {
    name: "Acesso Rápido",
    layout: "/admin",
    path: "quicklinks",
    icon: <MdWeb className="h-6 w-6" />,
    component: <QuickLinks />,
  },
  {
    name: "Vendas",
    layout: "dropdown",
    permission: "planilhas_menu",
    admin: false,
    icon: <SiGooglesheets className="h-6 w-6" />,
    dropdownLinks: [
      // {
      //   name: "Dashboard",
      //   layout: "/admin",
      //   path: "sheets/dashboard",
      //   icon: <RiDashboardFill className="h-6 w-6" />,
      //   admin: true,
      //   component: <SheetsDashboard />,
      // },
      // {
      //   name: "Administrador",
      //   layout: "/admin",
      //   path: "sheets/admin",
      //   icon: <IoSettings className="h-6 w-6" />,
      //   admin: true,
      //   component: <SheetsAdmin />,
      // },
    //   {
    //     name: "Minhas Planilhas",
    //     layout: "/admin",
    //     path: "sheets/mysheets",
    //     icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
    //     component: <UserSheets />,
    //   },
		// {
		// 	name: "Dashboard",
		// 	layout: "/admin",
		// 	path: "financeiro/dashboard",
		// 	icon: <MdDashboard className="h-6 w-6" />,
		// 	component: <ExpensesDashboard />,
		// 	admin: false,
		// },
		{
			name: "Lançamento",
			layout: "/admin",
			path: "sheets/default/sales",
			icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
			component: <SalesSheet />,
		},
    ]
  },
  {
    name: "Contratos",
    layout: "dropdown",
    admin: false,
    permission: "contracts_menu",
    icon: <FaFileContract className="h-6 w-6" />,
    dropdownLinks: [
    //   {
    //     name: "Dashboard",
    //     layout: "/admin",
    //     path: "contracts/dashboard",
    //     icon: <MdBarChart className="h-6 w-6" />,
    //     component: <ContractsDashboard />,
    //     admin: false,
    //   },
      {
        name: "Contratos Registrados",
        layout: "/admin",
        path: "contracts/list",
        icon: <FaClipboardList className="h-6 w-6" />,
        component: <ContractsList />,
        admin: false,
      },
      {
        name: "Novo Contrato",
        layout: "/admin",
        path: "contracts/new",
        icon: <MdAdd className="h-6 w-6" />,
        component: <ContractsNew />,
        admin: false,
      },
    ]
  },
  {
    name: "CRM",
    layout: "dropdown",
    permission: "crm_menu",
    admin: false,
    icon: <HiChartPie className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Organizações",
        layout: "/admin",
        path: "clients",
        icon: <MdHome className="h-6 w-6" />,
        component: <Clients />,
      },
      {
        name: "Contatos",
        layout: "/admin",
        path: "crm/contacts",
        icon: <IoIosPeople className="h-6 w-6" />,
        component: <Contacts />,
      },
    ]
  },
  {
    name: "Propostas",
    layout: "dropdown",
    icon: <MdCalculate className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Elaborar Proposta",
        layout: "/admin",
        path: "novo-orcamento",
        icon: <MdAdd className="h-6 w-6" />,
        component: <NewOrder />,
      },
      {
        name: "Propostas Enviadas",
        layout: "/admin",
        path: "orcamentos",
        icon: <MdAttachMoney className="h-6 w-6" />,
        component: <Orcamentos />,
      },
    ]
  },
  {
    name: "Business Control",
    layout: "dropdown",
    permission: "glpi_menu",
    admin: false,
    icon: <PiWrench className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Incidentes",
        layout: "/admin",
        path: "glpi/tickets",
        icon: <TiTicket className="h-6 w-6" />,
        component: <GLPITickets />,
      },
      // {
      //   name: "Implantações",
      //   layout: "/admin",
      //   path: "glpi/services",
      //   icon: <MdDesignServices className="h-6 w-6" />,
      //   component: <Contacts />,
      // },
    ]
  },
  {
    name: "Financeiro",
    layout: "dropdown",
    admin: false,
    permission: "financeiro_menu",
    icon: <BsCash className="h-6 w-6" />,
    dropdownLinks: [
		// {
		// 	name: "Dashboard",
		// 	layout: "/admin",
		// 	path: "sales/dashboard",
		// 	icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
		// 	component: <DashboardFinanceiro />,
		// },
		{
			name: "Salários",
			layout: "/admin",
			path: "financeiro/salarys",
			icon: <TbUserDollar className="h-6 w-6" />,
			component: <RelatorioSalario />,
			admin: false,
		},
		{
			name: "Colaboradores",
			layout: "/admin",
			path: "financeiro/funcionarios",
			icon: <MdPeople className="h-6 w-6" />,
			component: <FuncionariosList />,
			admin: false,
		},
		{
			name: "RDV",
			layout: "/admin",
			path: "sheets/default/rdv",
			icon: <MdPeople className="h-6 w-6" />,
			component: <RDVSheet />,
			admin: false,
		},
		{
			name: "Comissões Parceiro",
			layout: "/admin",
			path: "financeiro/comissoesparceiro",
			icon: <MdPeople className="h-6 w-6" />,
			component: <ComissoesDistribuidor />,
			admin: false,
		},
		{
			name: "Despesas por Operação",
			layout: "/admin",
			path: "financeiro/despesasoperacao",
			icon: <MdPeople className="h-6 w-6" />,
			component: <DespesasOperacao />,
			admin: false,
		},
		{
			name: "Notas Fiscais",
			layout: "/admin",
			permission: "financeiro_admin",
			path: "financeiro/nfs",
			icon: <MdPeople className="h-6 w-6" />,
			component: <NFsList />,
			admin: false,
		},
		{
			name: "Configurações",
			layout: "/admin",
			permission: "financeiro_admin",
			path: "financeiro/configuracoes",
			icon: <MdPeople className="h-6 w-6" />,
			component: <ConfiguracoesFinanceiro />,
			admin: false,
		},
    ]
  },
  {
    name: "Pós Venda",
    layout: "dropdown",
    permission: "pos_venda",
    admin: false,
    icon: <PiWrench className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Pedidos",
        layout: "/admin",
        path: "posvenda/pedidos",
        icon: <TiTicket className="h-6 w-6" />,
        component: <PosVendaSheet />,
      },
      // {
      //   name: "Implantações",
      //   layout: "/admin",
      //   path: "glpi/services",
      //   icon: <MdDesignServices className="h-6 w-6" />,
      //   component: <Contacts />,
      // },
    ]
  },

  {
    name: "B.I.",
    layout: "dropdown",
    permission: "business_inteligence",
    admin: false,
    icon: <PiWrench className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Vendas",
        layout: "/admin",
        path: "bi/vendas",
        icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
        component: <DashboardVendas />,
      },
      {
        name: "Financeiro",
        layout: "/admin",
        path: "bi/financeiro",
        icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
        component: <DashboardFinanceiro />,
      },
    //   {
    //     name: "Financeiro v1",
    //     layout: "/admin",
    //     path: "bi/propostasv1",
    //     icon: <PiMicrosoftExcelLogo className="h-6 w-6" />,
    //     component: <DashboardsV1 />,
    //   },
      {
        name: "Contratos",
        layout: "/admin",
        path: "bi/contratos",
        icon: <MdBarChart className="h-6 w-6" />,
        component: <DashboardContratos />,
      },
    ]
  },

  {
    name: "Administrador",
    layout: "dropdown",
    admin: true,
    icon: <MdAdminPanelSettings className="h-6 w-6" />,
    dropdownLinks: [
      {
        name: "Usuários e Cargos",
        layout: "/admin",
        path: "users",
        icon: <MdPeople className="h-6 w-6" />,
        component: <Users />,
        admin: true,
      },
      {
        name: "Configurar Proposta",
        layout: "/admin",
        path: "pdf",
        icon: <BsFilePdf className="h-6 w-6" />,
        component: <PDAdminPage />,
        admin: true,
      },
      {
        name: "Categorias",
        layout: "/admin",
        path: "categorys",
        icon: <MdPages className="h-6 w-6" />,
        component: <Categorys />,
        admin: true,
      },
      {
        name: "Configurar Taxas",
        layout: "/admin",
        path: "fees",
        icon: <FiPercent className="h-6 w-6" />,
        component: <TaxasConfig />,
        admin: true,
      },
      {
        name: "Comissões Globais",
        layout: "/admin",
        path: "globalcomissions",
        icon: <FiPercent className="h-6 w-6" />,
        component: <ComissoesGlobais />,
        admin: true,
      },
      {
        name: "Escopos de Serviço",
        layout: "/admin",
        path: "scopesofservice",
        icon: <AiOutlineAim className="h-6 w-6" />,
        component: <ScopesOfService />,
        admin: true,
      },
      {
        name: "Descontos Pendentes",
        layout: "/admin",
        path: "orders/peding",
        icon: <MdDiscount className="h-6 w-6" />,
        component: <PendingDiscounts />,
        admin: true,
      },
      {
        name: "Operações",
        layout: "/admin",
        path: "operacoes",
        icon: <MdDiscount className="h-6 w-6" />,
        component: <Operacoes />,
        admin: true,
      },
	  {
		name: "Empresas Cadastradas",
		layout: "/admin",
		path: "financeiro/empresas",
		icon: <MdPeople className="h-6 w-6" />,
		component: <EmpresasCadastradas />,
		admin: true,
	  },
    //   {
    //     name: "Relatório de Propostas",
    //     layout: "/admin",
    //     path: "orders/ordersreport",
    //     icon: <MdAttachMoney className="h-6 w-6" />,
    //     component: <RelatorioOrcamentos />,
    //     admin: true,
    //   },
    //   {
    //     name: "Relatório de Metas",
    //     layout: "/admin",
    //     path: "orders/goalsreport",
    //     icon: <HiDocumentReport className="h-6 w-6" />,
    //     component: <RelatorioMetas />,
    //     admin: true,
    //   },
    ]
  },




  ////////////////////HIDDEN ROUTES
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Perfil",
    layout: "/admin",
    hidden: true,
    path: "profile",
    icon: <MdLock className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Infos Funciuonaio",
    layout: "/admin",
    hidden: true,
    path: "funcionarios/edit/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <FuncionariosEdit />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "users/edit/user/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditUser />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "users/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewUser />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "roles/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewRole />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "grupos/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewGrupo />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "users/edit/role/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditRole />,
  },
  {
    name: "Usuários",
    layout: "/admin",
    hidden: true,
    path: "users/edit/grupo/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditGrupo />,
  },

  {
    name: "PDF",
    layout: "/admin",
    hidden: true,
    path: "pdf/edit/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditPDFPage />,
  },

  {
    name: "Clientes",
    layout: "/admin",
    hidden: true,
    path: "clients/edit/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditClient />,
  },
  {
    name: "Clientes",
    layout: "/admin",
    hidden: true,
    path: "clients/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewClient />,
  },
  {
    name: "Operações",
    layout: "/admin",
    hidden: true,
    path: "operacoes/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewOperacao />,
  },
  {
    name: "Operações",
    layout: "/admin",
    hidden: true,
    path: "operacoes/edit/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditOperacao />,
  },
  {
    name: "Orçamentos",
    layout: "/admin",
    hidden: true,
    path: "orcamentos/edit/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditOrder />,
  },

  {
    name: "Escopos de Serviço",
    layout: "/admin",
    hidden: true,
    path: "scopesofservice/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewScope />,
  },
  {
    name: "Escopos de Serviço",
    layout: "/admin",
    hidden: true,
    path: "scopesofservice/edit/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <EditScope />,
  },

  {
    name: "Novo Contato",
    layout: "/admin",
    hidden: true,
    path: "crm/contacts/new",
    icon: <MdHome className="h-6 w-6" />,
    component: <NewContact />,
  },
  {
    name: "Editar Contato",
    layout: "/admin",
    path: "crm/contacts/edit/:id",
    hidden: true,
    icon: <MdHome className="h-6 w-6" />,
    component: <EditContact />,
  },
  {
	name: "Editar Contrato",
	layout: "/admin",
	path: "contracts/edit/:id",
    hidden: true,
	icon: <MdAdd className="h-6 w-6" />,
	component: <ContractsEdit />,
	admin: false,
  },
  {
    name: "Preencher Planilha",
    layout: "/admin",
      hidden: true,
    path: "sheets/mysheets/:id",
    icon: <IoIosPeople className="h-6 w-6" />,
    component: <SheetsUserSheet />,
  },
//   {
//     name: "Preencher Planilha RDV",
//     layout: "/admin",
//       hidden: true,
//     path: "sheets/default/rdv",
//     icon: <IoIosPeople className="h-6 w-6" />,
//     component: <RDVSheet />,
//   },
//   {
//     name: "Preencher Planilha de Vendas",
//     layout: "/admin",
//       hidden: true,
//     path: "sheets/default/sales",
//     icon: <IoIosPeople className="h-6 w-6" />,
//     component: <SalesSheet />,
//   },
];
export default routes;
